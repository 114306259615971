<template>
    <el-dialog
            title="借阅申请"
            :close-on-click-modal="false"
            v-dialogDrag
            width="80%"
            :visible.sync="visible">
        <el-row>
            <el-col :span="24" style="font-weight:700;font-size: 20px">申请借阅的档案</el-col>
        </el-row>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                max-height="250"
                class="table m_b2">
<!--            <el-table-column prop="status" label="序号" width="50">-->
<!--                <template slot-scope="scope">-->
<!--                    <span>{{(pageNo-1)*pageSize+(scope.$index + 1)}}</span>-->
<!--                </template>-->
<!--            </el-table-column>-->
            <el-table-column show-overflow-tooltip
                             prop="exhibitionId"
                             label="展览编号">
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="exhibitionName"
                    label="展览名称">
            </el-table-column>
            <el-table-column
                    prop="fieldName1"
                    label="文件编号">
            </el-table-column>
            <el-table-column
                    prop="fileName"
                    label="文件名称">
            </el-table-column>
            <el-table-column
                    show-overflow-tooltip
                    prop="archivalCode"
                    label="档号">
            </el-table-column>
            <el-table-column width="150"
                             fixed="right"
                             label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="del(scope.$index)">移除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="f_w m_b2">填写借阅单</div>
        <el-form ref="inputForm" :model="inputForm" label-width="120px" :rules="dataRule">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="借阅人：">
                        <el-input v-model="inputForm.name" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="借阅时间：" prop="applyDate" >
                        <el-date-picker style="width: 100%"
                                v-model="inputForm.applyDate"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择日期时间" :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="借阅目的：" prop="borrowingPurpose" >
                        <el-input
                                type="textarea"
                                :rows="3"
                                placeholder="请输入内容"
                                v-model.trim="inputForm.borrowingPurpose" maxlength="100">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="备注：" prop="remarks">
                        <el-input
                                type="textarea"
                                :rows="3"
                                placeholder="请输入内容"
                                v-model.trim="inputForm.remarks" maxlength="100">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="save()" type="primary" >确认</el-button>
            <el-button size="small" @click="visible = false">关闭</el-button>
        </span>
    </el-dialog>

</template>

<script>
    export default {
        data() {
            return {
                pickerOptions: {
                    disabledDate(v) {
                        return v.getTime() < new Date().getTime() - 86400000;//  - 86400000是否包括当天
                    }
                },
                dataList:[],
                visible: false,
                loading: false,
                inputForm: {
                    name: JSON.parse(sessionStorage.getItem('userInfo')).name,
                    applyDate:"",
                    borrowingPurpose:"",
                    remarks:""
                },
                dataRule: {
                    applyDate: [{required: true, message: '借阅时间不能为空', trigger: 'change'}],
                    borrowingPurpose: [{required: true, message: '借阅目的不能为空', trigger: 'blur'}],
                },
            }
        },
        methods: {
            init(dataList) {
                this.dataList = JSON.parse(JSON.stringify(dataList))
                this.visible = true
                this.loading = false
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                })
            },
            del(index){
                if(this.dataList.length>1){
                    this.dataList.splice(index, 1);    //删除数组中的该条数据
                }else{
                    this.$message.warning("当前只有一个借阅文件，不允许删除！")
                }
            },
            save(){
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        if(new Date(this.inputForm.applyDate) < new Date()) {
                            this.$message.warning("借阅时间小于当前时间，请重新选择！")
                        } else {
                            let fileIds = this.dataList.map(item => {
                                return item.id
                            }).join(',')
                            let approvalIds = this.dataList.map(item => {
                                return item.createId
                            }).join(',')
                            let fileName = this.dataList.map(item => {
                                return item.fileName
                            }).join(',')
                            this.$axios(this.api.common.borrowBatchSave, {
                                fileIds:fileIds,
                                applyDate:new Date(this.inputForm.applyDate),
                                borrowingPurpose:this.inputForm.borrowingPurpose,
                                remarks:this.inputForm.remarks,
                                approvalIds:approvalIds,
                                fileName:fileName
                            }, 'post').then(data => {
                                if(data.status){
                                    this.$message.success('提交成功')
                                }else{
                                    this.$message.warning("不可重复借阅")
                                }
                                this.visible = false
                                this.$emit('refreshDataList')
                            })
                        }
                    }else {
                        return false;
                    }
                })
            }

        }
    }
</script>
