<template>
    <el-dialog title="高级查询" :visible.sync="visible" width="55%">
        <el-form size="small" ref="addForm" :model="addForm" label-width="0px">
            <div class="m_b1">筛选条件</div>
            <el-row :gutter="20" v-for="(item, index) in addForm.conditionList" :key="index">
                <el-col :span="8">
                    <el-form-item :prop="`conditionList.${index}.fieldName`"
                                  :rules="[{required: true, whitespace: true, message: '筛选的字段名不能为空', trigger: 'blur'}]">
                        <el-select v-model="item.fieldName" placeholder="请选择要筛选的字段名" size="small" @change="changeFieldName" style="width: 100%">
                            <el-option
                                    v-for="(item,index) in fieldNameOpt"
                                    :key="index"
                                    :label="item.recordName"
                                    :value="item.fieldEnname">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item :prop="`conditionList.${index}.equalOpt`" :rules="[{required: true, whitespace: true, message: '筛选条件不能为空', trigger: 'blur'}]">
                        <el-select v-model="item.equalOpt" placeholder="请选择条件" size="small" style="width: 100%" @change="equalOptChange">
                            <el-option
                                    v-for="itemC in $dictUtils.getDictList('senior_retrieve')"
                                    :key="itemC.value"
                                    :label="itemC.label"
                                    :value="itemC.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item :prop="`conditionList.${index}.condition`"
                                  v-if="equalOptpd">
<!--                        :rules="[{required: true, whitespace: true, message: '筛选值不能为空', trigger: 'blur'}]"-->
                        <el-input v-model="item.condition" placeholder="请输入筛选值" maxlength="66" size="small"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="1">
                    <div class="el-icon-error" @click="del(index)"></div>
                </el-col>
            </el-row>
        </el-form>
        <el-button type="text" class="addCondition" @click="addCondition">+ 添加筛选条件</el-button>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="confirm()" v-noMoreClick>确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                addForm: {
                    conditionList: [
                        {
                            fieldName: '',
                            condition: '',
                            equalOpt: '',
                        }
                    ],
                },
                fieldNameOpt: [],
                equalOptpd:true
            }
        },
        methods: {
            equalOptChange(val){
                console.log(val)
                this.equalOptpd = true
                if(val=="is null" || val=="is not null" || val=="= ''" || val=="<> ''"){
                    this.equalOptpd = false
                }
            },
            init(config) {
                this.visible = true;
                let that = this;
                this.fieldNameOpt = JSON.parse(JSON.stringify(config))
                this.fieldNameOpt.forEach(function (v) {
                    v.fieldEnname=that.toLine(v.fieldEnname)
                })
                this.$nextTick(() => {
                    this.addForm.conditionList = [{
                        fieldName: '',
                        condition: '',
                        equalOpt: '',
                    }]
                    this.$refs['addForm'].resetFields();
                })
            },
            toLine(name) {
                return name.replace(/([A-Z])/g,"_$1").toLowerCase();
            },
            // 添加筛选条件
            addCondition() {
                if(this.addForm.conditionList.length < this.fieldNameOpt.length) {
                    this.addForm.conditionList.push({
                        fieldName: '',
                        condition: '',
                        equalOpt: '',
                    })
                }
            },
            // 删除筛选条件
            del(index) {
                this.$confirm(`您确定要删除这一条数据吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if(this.addForm.conditionList.length>1){
                        this.addForm.conditionList.splice(index, 1)
                    }
                })
            },
            // 确定
            confirm() {
                this.$refs['addForm'].validate((valid) => {
                    if (valid) {
                        this.visible = false;
                        this.$emit('getDataList', this.addForm.conditionList)
                    }
                })
            },
            // 筛选字段名改变
            changeFieldName(data) {},
        }
    }
</script>

<style scoped>
    .addCondition.el-button {
        font-size: 13px !important;
    }
    .el-icon-error {
        color: #bbb;
        cursor: pointer;
        margin-top: 10px;
    }
</style>
